import React from 'react'
import About from "../components/About";

const AboutPage = () => {
  return (
    <>
    <About />
    </>
  )
}

export default AboutPage