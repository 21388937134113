export const slides = [
  {
    img: "https://rassat.com.ar/images/home5.png",
    id: 1,
    title: "Slide to Show 2",
  },
  {
    img: "https://rassat.com.ar/images/home2.jpg",
    id: 2,
    title: "Slide to Show 2",
    description: "Abrí la puerta de tu casa desde una pantalla o tu celular",
  },
  {
    img: "https://rassat.com.ar/images/home6.jpg",
    id: 3,
    title: "Slide to Show 2",
    description:
      "Sistema de Alarmas inalambrico para tu hogar o empresa. Hasta 96 zonas",
  },
  {
    img: "https://rassat.com.ar/images/home4.png",
    id: 4,
    title: "Slide to Show 3",
  },

  {
    img: "https://rassat.com.ar/images/home3.jpeg",
    id: 5,
    title: "Slide to Show 1",
    description:
      "Control de accesos con reconocimiento facial, huella dactilar, llave de proximidad y control de temperatura.",
  },
];
