import React from 'react'
import Form from '../components/form/Form'

const Contact = () => {
  return (
    <>
    <Form />
    </>
  )
}

export default Contact