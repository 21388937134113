import React from 'react'
import Service from '../components/Service'

const Services = () => {
  return (
    <div>
      <Service />
    </div>
  )
}

export default Services